@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Plus Jakarta Sans", sans-serif;
  @apply bg-tgpro-black-1;
}

/* width */
::-webkit-scrollbar {
  @apply w-1 h-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-tgpro-black-1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-tgpro-blue-1 rounded-md;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
